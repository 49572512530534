<template>
  <div>
    <el-form ref="checkForm" :model="listQuery" label-width="140px">
      <el-row class="search">
        <el-col :span="18">
          <el-row>
            <el-col :span="7">
              <el-form-item label="部门名称" prop="name">
                <el-input
                  placeholder="请输入部门名称"
                  v-model="listQuery.name"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="所属单位" prop="parentId">
                <selectOrg
                  ref="selectOrg"
                  @select="selectOrg"
                ></selectOrg>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="6">
          <el-row>
            <el-col :span="24">
              <el-form-item>
                <el-button type="primary" @click="search">查询</el-button>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="reset">重置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-button type="primary" @click="add">新增</el-button>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-form>

    <div class="jy_table">
      <ctable
        ref="ctable"
        :columns="columns"
        :data="tableList"
        :total="total"
        operateWidth="200"
        isGenerate
        @cellClick="cellClick"
        @update="initData"
      >
      </ctable>
    </div>

    <dept
      :rowData="rowData"
      :isEdit="isEdit"
      :show="showDept"
      @close="closeDialog"
    ></dept>

    <member
      :rowData="rowData"
      @close="closeDialog"
      :show="showMember"
    ></member>
  </div>
</template>

<script>
import dept from "./dept";
import member from "./member";
import selectOrg from "@/components/dialog/selectOrg";
export default {
  name: "index",
  data() {
    return {
      showDept: false,
      showMember: false,

      tableList: [],
      columns: [
        {
          prop: "name",
          title: "部门名称",
          width: 150,
        },
        {
          prop: "orgName",
          title: "数据所属单位",
          width: 150,
        },
        {
          prop: "deptStaffName",
          title: "成员",
          width: 200,
        },
        {
          prop: "status",
          title: "状态",
          width: 150,
          formatter: (row, key, item) => {
            return item[key] === 1 ? "启用" : "禁用";
          },
        },
        {
          prop: "createUserName",
          title: "创建用户",
          width: 150,
        },
        {
          prop: "createTime",
          title: "创建时间",
          width: 250,
        },
      ],
      btnList: [
        {
          index: 1,
          title: "编辑",
        },
        {
          index: 2,
          title: "删除",
        },
        {
          index: 3,
          title: "禁用",
        },
        {
          type: "text",
          title: "启用",
        },
        {
          index: 5,
          title: "添加成员",
        },
      ],
      listQuery: {
        name: "",
        parentId: "",
      },
      total: 0,
      isEdit: false,
      rowData: {},
    };
  },
  components: {
    dept,
    member,
    selectOrg,
  },
  created() {
    this.$nextTick(() => {
        this.initData();
    })
  },
  methods: {
    search() {
      this.initData();
    },
    reset() {
      this.listQuery = {
        name: "",
        parentId: "",
      };
      this.$refs.selectOrg.reset();
    },
    async initData() {
      const page = this.$refs.ctable.getPager();
      const result = await this.$http({
        url: "/sys/org/dept/queryPage",
        data: {
            ...this.listQuery,
            ...page
        },
      });
      this.tableList = result.detail.list;
      this.total = result.detail.total;
      this.tableList.forEach((v) => {
        v.btnList = this.gernerateBtns(v);
      });
    },
    gernerateBtns(row) {
      let btns = [
        {
          index: 1,
          title: "编辑",
          type: "text",
        },
        {
          index: 2,
          title: "删除",
          type: "text",
        },
        {
          index: 5,
          title: "添加成员",
          type: "text",
        },
      ];
      if (row.status === 1) {
        btns.push({
          title: "禁用",
          index: 3,
          type: "text",
        });
      } else {
        btns.push({
          title: "启用",
          index: 4,
          type: "text",
        });
      }
      return btns;
    },
    add() {
        this.isEdit = false;
        this.showDept = true;
    },
    cellClick(index,btnIndex,item) {
      this.rowData = item;
      if (btnIndex === 1) {
        this.showDept = true;
        this.isEdit = true;
      } else if (btnIndex === 2) {
        this.$confirm("是否删除该部门？", "删除", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$http({
              url: "/sys/org/dept/deleteOrgId",
              data: {orgId: item.orgId}
          }).then(() => {
            this.$message({
              message: "删除成功",
              showClose: true,
              type: "success",
            });
            this.initData();
          });
        });
      } else if (btnIndex === 3) {
        this.$confirm("是否禁用该部门？", "禁用", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$http({
              url: "/sys/org/dept/disable",
              data: {orgId: item.orgId, status: 0}
          }).then(() => {
            this.$message.success({
              message: "禁用成功",
              duration: 3000
            });
            this.initData();
          });
        });
      } else if (btnIndex === 4) {
        this.$confirm("是否启用该部门？", "启用", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$http({
              url: "/sys/org/dept/disable",
              data: {orgId: item.orgId, status: 1}
          }).then(() => {
            this.$message.success({
              message: "启用成功",
              duration:3000
            });
            this.initData();
          });
        });
      } else if (btnIndex === 5) {
        this.showMember = true;
      }
    },
    closeDialog() {
      this.showDept = false;
      this.showMember = false;
      this.initData();
    },
    selectOrg(data) {
      this.listQuery.parentId = data.orgId;
    },
  },
};
</script>
