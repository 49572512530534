<template>
  <el-dialog
    title="添加成员"
    :visible.sync="visible"
    append-to-body
    @open="initData"
    @close="handleClose"
  >
    <el-button type="primary" style="margin-bottom:10px;" @click="addMember"
      >新增</el-button
    >
    <el-table :data="members">
      <el-table-column label="序号" prop="id"></el-table-column>
      <el-table-column label="姓名" prop="name"></el-table-column>
      <el-table-column label="角色" prop="role"></el-table-column>
      <el-table-column label="电话" prop="phone"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="handleDelete(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="新增成员" :visible.sync="memberShow" append-to-body>
      <el-form :model="memForm">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="memForm.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="role">
          <el-input
            v-model="memForm.role"
            placeholder="请输入只能角色"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="phone">
          <el-input
            v-model="memForm.phone"
            placeholder="请选择只能角色"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleAddMember">确定</el-button>
          <el-button @click="handleClose">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-dialog>
</template>

<script>
export default {
  name: "member",
  props: {
    show: Boolean,
    rowData: Object,
  },
  data() {
    return {
      memberShow: false,
      visible: this.show,
      members: [],
      memForm: {
        name: "",
        role: "",
        phone: "",
        orgType: "2",
      },
    };
  },
  watch: {
    show() {
      this.visible = this.show;
    },
  },
  methods: {
    async initData() {
      const result = await this.$http({
        url: "/sys/org/dept/queryNameByOrgId",
        data: { orgId: this.rowData.orgId },
      });
      this.members = result.detail;
    },
    async handleDelete(data) {
      this.$http({
        url: "/sys/org/dept/deleteNameByOrgId",
        data: { orgId: data.orgId },
      }).then(() => {
        this.$message.success({
          message: "删除成功",
          duration: 3000,
        });
        this.initData();
      });
    },
    handleClose() {
      this.$emit("close");
    },
    addMember() {
      this.memberShow = true;
    },
    async handleAddMember() {
      this.$http({
        url: "/sys/org/dept/add",
        data: { ...this.memForm, parentId: this.rowData.orgId },
      }).then(() => {
        this.$message.success({
          message: "新增成功",
          duration: 3000,
        });
        this.initData();
        this.memberShow = false;
      });
    },
  },
  components: {},
};
</script>
