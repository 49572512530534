<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="40%"
    @close="handleClose"
    @open="openDialog"
  >
    <el-form :model="form" label-position="left">
      <el-form-item label="部门名称" prop="name">
        <el-input placeholder="输入部门名称" v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="部门职责" prop="orgObligation">
        <el-input
          type="textarea"
          :rows="2"
          v-model="form.orgObligation"
        ></el-input>
      </el-form-item>
      <el-form-item label="数据所属单位" prop="orgId">
        <selectOrg
          ref="selectOrg"
          @select="selectOrg"
          v-if="!isEdit"
        ></selectOrg>
        <label v-else>{{ rowData.orgId }}</label>
      </el-form-item>
    </el-form>
    <el-table v-if="isEdit" :data="tableData" style="width: 100%">
      <el-table-column prop="id" label="序号" width="180"></el-table-column>
      <el-table-column prop="name" label="姓名" width="180"></el-table-column>
      <el-table-column prop="role" label="角色"></el-table-column>
      <el-table-column prop="phone" label="联系电话"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button type="text" @click="handleDelete(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="footer">
      <el-button type="primary" @click="handleAddDept">确定</el-button>
      <el-button @click="handleClose">取消</el-button>
    </div>
    <el-dialog title="编辑成员" :visible.sync="memberShow" append-to-body>
      <el-form :model="memForm">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="memForm.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="role">
          <el-input v-model="memForm.role" placeholder="请输入角色"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="phone">
          <el-input
            v-model="memForm.phone"
            placeholder="请输入联系电话"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleMemEdit">确定</el-button>
          <el-button @click="closeAddMenDialog">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-dialog>
</template>

<script>
import selectOrg from "@/components/dialog/selectOrg";
export default {
  name: "dept",
  props: {
    show: Boolean,
    isEdit: Boolean,
    rowData: Object,
  },
  data() {
    return {
      dialogVisible: this.show,
      form: {
        orgType: "1",
        name: "",
        orgObligation: "",
      },
      memForm: {
        name: "",
        role: "",
        phone: "",
      },
      tableData: [],
      memberShow: false,
      title: "",
    };
  },
  watch: {
    show() {
      this.dialogVisible = this.show;
    },
  },
  methods: {
    reset() {
      this.form = {
        orgType: "1",
        name: "",
        orgObligation: "",
      };
      if (!this.isEdit) {
        this.$refs.selectOrg.reset();
      }
    },
    openDialog() {
      if (this.isEdit) {
        this.form.name = this.rowData.name;
        this.form.orgObligation = this.rowData.orgObligation;
        this.form.orgId = this.rowData.orgId;
        this.form.parentId = this.rowData.parentId;
        this.memForm.parentId = this.rowData.orgId;
        this.initData();
        this.title = "编辑组织机构";
      } else {
        this.title = "新增组织机构";
      }
    },
    async initData() {
      const result = await this.$http({
        url: "/sys/org/dept/queryNameByOrgId",
        data: { orgId: this.rowData.orgId },
      });
      this.tableData = result.detail;
    },
    handleClose() {
      this.reset();
      this.$emit("close");
    },
    closeAddMenDialog() {
      this.memForm = {};
      this.memberShow = false;
    },
    handleEdit(data) {
      this.memForm.name = data.name;
      this.memForm.role = data.role;
      this.memForm.phone = data.phone;
      this.memForm.orgId = data.orgId;
      this.memberShow = true;
    },
    async handleMemEdit() {
      this.$http({
        url: "/sys/org/dept/update",
        data: this.memForm,
      }).then(() => {
        this.$message.success({
          message: "修改成功",
          duration: 3000,
        });
        this.closeAddMenDialog();
      });
    },
    async handleDelete(data) {
      this.$http({
        url: "/sys/org/dept/deleteNameByOrgId",
        data: { orgId: data.orgId },
      }).then(() => {
        this.$message.success({
          message: "删除成功",
          showClose: true,
        });
        this.initData();
      });
    },
    async handleAddDept() {
      let result = null;
      if (this.isEdit) {
        result = await this.$http({
          url: "/sys/org/dept/update",
          data: this.form,
        });
      } else {
        result = await this.$http({
          url: "/sys/org/dept/add",
          data: this.form,
        });
      }
      this.$message.success({
        message: result.msg,
        duration: 3000,
      });
      this.handleClose();
    },
    selectOrg(data) {
      this.form.parentId = data.orgId;
    },
  },
  components: {
    selectOrg,
  },
};
</script>

<style scoped></style>
